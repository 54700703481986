
<template>
    <Header />
      <router-view />
    <Footer />
</template>

<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import TopNavigation from './components/TopNavigation.vue';

export default {
  components: {
    Header,
    Footer,
    TopNavigation,
  },
};
</script>

<style>
</style>
