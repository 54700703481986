<template>
  <nav class="shop--navigation block-group">
    <ul
      class="navigation--list block-group"
      style="display: block"
      role="menubar"
    >
      <!-- Alle Links aus der zweiten Ebene direkt in die erste Ebene verschoben -->
      <li class="navigation--entry entry--service" role="menuitem">
        <router-link
          class="entry--link"
          to="/schadenmeldung"
          title="Schadenmeldung"
          :class="{ 'is--active': $route.path === '/schadenmeldung' }"
        >
          <span class="service--display">Schadenmeldung</span>
        </router-link>
      </li>
      <li class="navigation--entry entry--service" role="menuitem">
        <router-link
          class="entry--link"
          to="/faq-sonnenDrive"
          title="FAQ zu sonnenDrive"
          :class="{ 'is--active': $route.path === '/faq-sonnenDrive' }"
        >
          <span class="service--display">FAQ zu sonnenDrive</span>
        </router-link>
      </li>
      <li class="navigation--entry entry--service" role="menuitem">
        <router-link
          class="entry--link"
          to="/faq-elektroauto"
          title="FAQ zum Elektroauto"
          :class="{ 'is--active': $route.path === '/faq-elektroauto' }"
        >
          <span class="service--display">FAQ zum Elektroauto</span>
        </router-link>
      </li>
      <li class="navigation--entry" role="menuitem">
        <router-link
          class="entry--link"
          to="/so-geht-sonnenDrive"
          title="So geht sonnenDrive"
          :class="{ 'is--active': $route.path === '/so-geht-sonnenDrive' }"
        >
          <span class="service--display">So geht sonnenDrive</span>
        </router-link>
      </li>
      <li class="navigation--entry entry--service" role="menuitem">
        <router-link class="entry--link" to="/downloads" title="Downloads" :class="{ 'is--active': $route.path === '/downloads' }" >
          <span class="service--display">Downloads</span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "TopNavigation",
};
</script>
<style>

</style>
