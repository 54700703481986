<template>
  <header class="header-main">
    <div class="container">
      <div class="row align-items-center header-row justify-content-between">
        <div class="header-logo-col">
          <div class="header-logo-main">
            <a class="header-logo-main-link" href="/" title="Zur Startseite gehen">
              <picture class="header-logo-picture">
                <svg xmlns="http://www.w3.org/2000/svg" width="208.555" height="80" viewBox="0 0 208.555 80"
                  class="img-fluid header-logo-main-img">
                  <g id="Gruppe_7" data-name="Gruppe 7" transform="translate(-353.6 -16.68)">
                    <g id="Gruppe_6" data-name="Gruppe 6" transform="translate(353.6 40.006)">
                      <path id="Pfad_9" data-name="Pfad 9"
                        d="M365.973,66.069a22.662,22.662,0,0,1,.222-3.075h-.075a6.16,6.16,0,0,1-5.816,3.482c-4.482,0-6.7-3.37-6.7-8.63,0-6.224,3.63-10.522,9.744-10.522a16.888,16.888,0,0,1,2.333.148V39.027h4.7V66.069Zm-.3-14.706a11.6,11.6,0,0,0-2.482-.3c-2.7,0-4.631,1.7-4.631,6.262,0,3.445,1,5.224,3.149,5.224,2.482,0,3.964-2.89,3.964-6.041Z"
                        transform="translate(-353.6 -39.027)" fill="#bdce00" />
                      <path id="Pfad_10" data-name="Pfad 10"
                        d="M381.108,66.44a24.237,24.237,0,0,1-6.853-.888V39.027H379v7.927a22.223,22.223,0,0,1-.294,3.594h.035a5.9,5.9,0,0,1,5.557-3.261c4.409,0,6.779,3.187,6.779,8.632C391.073,62.142,387.443,66.44,381.108,66.44Zm1.853-15.225c-2.482,0-3.964,2.852-3.964,5.927v5.187a7.7,7.7,0,0,0,2.483.334c2.666,0,4.63-1.7,4.63-6.261C386.11,52.955,385.109,51.215,382.96,51.215Z"
                        transform="translate(-352.695 -39.027)" fill="#bdce00" />
                      <path id="Pfad_11" data-name="Pfad 11"
                        d="M400.592,66.44a24.252,24.252,0,0,1-6.855-.888V39.027h4.742v7.927a21.871,21.871,0,0,1-.3,3.594h.037a5.9,5.9,0,0,1,5.557-3.261c4.409,0,6.78,3.187,6.78,8.632C410.556,62.142,406.927,66.44,400.592,66.44Zm1.852-15.225c-2.482,0-3.964,2.852-3.964,5.927v5.187a7.69,7.69,0,0,0,2.482.334c2.667,0,4.63-1.7,4.63-6.261C405.592,52.955,404.592,51.215,402.443,51.215Z"
                        transform="translate(-351.841 -39.027)" fill="#bdce00" />
                      <path id="Pfad_12" data-name="Pfad 12"
                        d="M441.577,65.724a20.825,20.825,0,0,1,.185-3.112l-.038-.037a6.244,6.244,0,0,1-5.89,3.519,4.957,4.957,0,0,1-5.26-5.261c0-4.372,4.222-6.446,10.631-6.446v-.927c0-1.926-.926-2.926-3.593-2.926a12.629,12.629,0,0,0-5.186,1.3l-.854-3.741a20.583,20.583,0,0,1,6.855-1.187c5.853,0,7.52,2.409,7.52,6.224v7.854c0,1.482.038,3.224.111,4.742Zm-.372-8.261c-4.815,0-6.112,1.26-6.112,2.853a2.051,2.051,0,0,0,2.186,2.148c2.333,0,3.926-2.222,3.926-4.446Z"
                        transform="translate(-350.227 -38.682)" fill="#bdce00" />
                      <path id="Pfad_13" data-name="Pfad 13"
                        d="M461.472,65.707a20.473,20.473,0,0,1,.223-3.3h-.038a6.718,6.718,0,0,1-6.261,3.667c-4.149,0-5.483-2.629-5.483-5.853V47.3h4.7v11.78c0,1.889.52,3.038,2.3,3.038,2.408,0,4.26-2.7,4.26-5.926V47.3h4.742V65.707Z"
                        transform="translate(-349.38 -38.665)" fill="#bdce00" />
                      <path id="Pfad_14" data-name="Pfad 14"
                        d="M476.074,66.368c-4.077,0-5.336-1.482-5.336-5.779V51.255h-3.186V47.587h3.186V41.919l4.7-1.26v6.928h4.483v3.668h-4.483V59.33c0,2.371.556,3.038,2.186,3.038a7.487,7.487,0,0,0,2.113-.26l.333,3.742A17.657,17.657,0,0,1,476.074,66.368Z"
                        transform="translate(-348.607 -38.955)" fill="#bdce00" />
                      <path id="Pfad_15" data-name="Pfad 15"
                        d="M489.917,66.093c-5.222,0-8.595-2.7-8.595-9.261,0-5.742,3.816-9.892,9.41-9.892,5.223,0,8.593,2.89,8.593,9.224C499.325,61.944,495.437,66.093,489.917,66.093Zm.482-15.152c-2.112,0-4.187,1.63-4.187,5.409s1.372,5.706,4.112,5.706c2.334,0,4.113-1.89,4.113-5.631C494.437,52.869,493.177,50.941,490.4,50.941Z"
                        transform="translate(-348.003 -38.68)" fill="#bdce00" />
                    </g>
                    <path id="Pfad_16" data-name="Pfad 16"
                      d="M512.675,65.719a26.007,26.007,0,0,1,.222-4.039l-.074-.037a6.825,6.825,0,0,1-6.52,4.372c-2.854,0-5-1.814-5-4.779,0-3.667,3.889-6.26,11.336-6.26V53.569c0-3-1.074-4.669-4.37-4.669a12.384,12.384,0,0,0-5.446,1.408l-.593-1.963a16.317,16.317,0,0,1,6.408-1.333c4.964,0,6.335,2.519,6.335,5.889v7.114c0,1.888.038,4.039.074,5.7Zm-.038-8.928c-6.964,0-8.964,2-8.964,4.074a3.062,3.062,0,0,0,3.185,3.223c3.484,0,5.78-3.481,5.78-6.853Z"
                      transform="translate(6.472 1.329)" fill="#5d5d5d" />
                    <path id="Pfad_17" data-name="Pfad 17"
                      d="M525.438,66.291a23.712,23.712,0,0,1-5.967-.665v-26.6h2.336v9.668a16.328,16.328,0,0,1-.334,3.334l.074.038a6.706,6.706,0,0,1,6.447-4.7c4.333,0,6.816,2.926,6.816,8.668C534.81,62.7,531.068,66.291,525.438,66.291Zm2.184-17.04c-3.963,0-5.815,4.632-5.815,7.929v6.7a11.26,11.26,0,0,0,3.853.555c4.039,0,6.7-2.408,6.7-8.187C532.364,51.881,530.882,49.25,527.622,49.25Z"
                      transform="translate(7.268 0.979)" fill="#5d5d5d" />
                    <path id="Pfad_18" data-name="Pfad 18"
                      d="M545.432,66.016c-4.889,0-8-3-8-9.447,0-5.149,3.113-9.557,8.631-9.557,4.3,0,8.039,2.371,8.039,9.223C554.1,61.608,550.84,66.016,545.432,66.016ZM545.8,48.9c-3.036,0-5.927,2.335-5.927,7.483,0,4.891,2.149,7.705,5.964,7.705,3.039,0,5.854-2.333,5.854-7.742C551.693,51.716,549.692,48.9,545.8,48.9Z"
                      transform="translate(8.055 1.329)" fill="#5d5d5d" />
                    <text id="Eine_Rate._Alles_drin." data-name="Eine Rate. Alles drin."
                      transform="translate(434.246 72.805)" fill="#bdce00" font-size="10.46" font-family="Helvetica">
                      <tspan x="0" y="8">Eine Rate. Alles drin.</tspan>
                    </text>
                    <line id="Linie_1" data-name="Linie 1" y2="80" transform="translate(423.072 16.68)" fill="none"
                      stroke="#bdce00" stroke-miterlimit="10" stroke-width="1.228" />
                  </g>
                </svg>

              </picture>
            </a>
          </div>
        </div>



        <div class="col-md-12 col-lg-auto order-3 order-xl-1 header-navi-col d-xl-block w-auto">
          <div class="row">
            <div class="col col-header">
              <div class="nav-main">
                <div class="main-navigation" id="mainNavigation" data-flyout-menu="true">
                  <div class="container">
                    <nav class="nav main-navigation-menu" itemscope="itemscope">
                      <div class="nav-link main-navigation-link with-submenu">
                        <a href="/#/FAQ/" title="Service/Hilfe">
                          <div class="main-navigation-link-text">
                            <span>
                              Service/Hilfe

                              <span class="icon icon-arrow-medium-down icon-xs">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12.672" height="7.75"
                                  viewBox="0 0 12.672 7.75">
                                  <path id="Pfad_874" data-name="Pfad 874" d="M-3584.324-1499.8l4.922,4.922,4.922-4.922"
                                    transform="translate(3585.738 1501.217)" fill="none" stroke="#f8f8f8"
                                    stroke-linecap="round" stroke-width="2"></path>
                                </svg>
                              </span>
                            </span>
                          </div>
                        </a>
                        <div class="sub-navigation">
                          <router-link class="nav-item nav-link is-level-0" to="/Faq" title="Faq" :class="{ 'active': $route.path === '/Faq' }"><span>FAQ</span></router-link>

                          <div class="sub-item-links"></div>

                          <router-link class="nav-item nav-link is-level-0" to="/Downloads" title="AGB" :class="{ 'active': $route.path === '/Downloads' }"><span>Downloads</span></router-link>
                          <div class="sub-item-links"></div>

                          <router-link class="nav-item nav-link is-level-0" to="/Schadenmeldung" title="AGB" :class="{ 'active': $route.path === '/Schadenmeldung' }"><span>Unfall- und Schadenmeldung</span></router-link>

                          <div class="sub-item-links"></div>
                        </div>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import TopNavigation from './TopNavigation.vue';

export default {
  name: "HeaderMain",
  components: {
    TopNavigation,
  },
};
</script>

<style>
</style>
