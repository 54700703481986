<template>
  <footer class="footer-main">
    <div class="container">

      <div id="footerColumns" class="row footer-columns" data-collapse-footer="true" role="list">
        <div class="col-md-6 footer-column js-footer-column">
          <div
            class="footer-column-headline footer-headline js-footer-column-headline js-collapse-footer-column-trigger"
            id="collapseFooterHotlineTitle" data-bs-target="#collapseFooterHotline" aria-expanded="true"
            aria-controls="collapseFooterHotline" role="listitem">
            Kontakt
            <div class="footer-column-toggle">
              <span class="footer-plus-icon">



                <span class="icon icon-plus">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24"
                    height="24" viewBox="0 0 24 24">
                    <defs>
                      <path
                        d="M11 11V3c0-.5523.4477-1 1-1s1 .4477 1 1v8h8c.5523 0 1 .4477 1 1s-.4477 1-1 1h-8v8c0 .5523-.4477 1-1 1s-1-.4477-1-1v-8H3c-.5523 0-1-.4477-1-1s.4477-1 1-1h8z"
                        id="icons-default-plus"></path>
                    </defs>
                    <use xlink:href="#icons-default-plus" fill="#758CA3" fill-rule="evenodd"></use>
                  </svg>

                </span>
              </span>
              <span class="footer-minus-icon">



                <span class="icon icon-minus">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24"
                    height="24" viewBox="0 0 24 24">
                    <defs>
                      <path id="icons-default-minus"
                        d="M3 13h18c.5523 0 1-.4477 1-1s-.4477-1-1-1H3c-.5523 0-1 .4477-1 1s.4477 1 1 1z"></path>
                    </defs>
                    <use xlink:href="#icons-default-minus" fill="#758CA3" fill-rule="evenodd"></use>
                  </svg>

                </span>
              </span>
            </div>
          </div>


          <div id="collapseFooterHotline" class="footer-column-content collapse js-footer-column-content footer-contact"
            aria-labelledby="collapseFooterHotlineTitle">
            <div class="footer-column-content-inner">
              <p class="footer-contact-hotline">
                <strong>Sie benötigen Unterstützung und Beratung?</strong>
              </p>
              <p> Schauen Sie bei <a href="http://Fleetpool.de">Fleetpool.de</a></p>
              <p></p>
            </div>
          </div>
        </div>

        <div class="col-md-6 footer-column js-footer-column">
          <div class="footer-column-headline footer-headline js-collapse-footer-column-trigger"
            data-bs-target="#collapseFooterTitle1" aria-expanded="true" aria-controls="collapseFooter1">

            Informationen
            <div class="footer-column-toggle">
              <span class="footer-plus-icon">



                <span class="icon icon-plus">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24"
                    height="24" viewBox="0 0 24 24">
                    <use xlink:href="#icons-default-plus" fill="#758CA3" fill-rule="evenodd"></use>
                  </svg>

                </span>
              </span>
              <span class="footer-minus-icon">



                <span class="icon icon-minus">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24"
                    height="24" viewBox="0 0 24 24">
                    <use xlink:href="#icons-default-minus" fill="#758CA3" fill-rule="evenodd"></use>
                  </svg>

                </span>
              </span>
            </div>
          </div>

          <div id="collapseFooter1" class="footer-column-content collapse js-footer-column-content"
            aria-labelledby="collapseFooterTitle1">
            <div class="footer-column-content-inner">
              <ul class="list-unstyled">
                <li class="footer-link-item">
                  <a class="footer-link" href="/#/Downloads/"
                    title="Downloads">
                    Downloads
                  </a>
                </li>

                <li class="footer-link-item">
                  <a class="footer-link"
                    href="/downloads/FP2022.11_0_DE_Verhaltenskodex_Code_of_Conduct.pdf"
                    target="_blank" title="Verhaltenskodex">
                    Verhaltenskodex
                  </a>
                </li>

                <li class="footer-link-item">
                  <a class="footer-link" href="https://report.whistleb.com/de/societegenerale" target="_blank"
                    title="Whistleblowing*">
                    Whistleblowing*
                  </a>
                </li>
              </ul>
            </div>
          </div>

        </div>




      </div>

      <div class="footer-logos">

      </div>

    </div>

    <div class="footer-bottom">
      <div class="container">
        <ul class="footer-service-menu-list list-unstyled">
          <li class="footer-service-menu-item"><a class="footer-service-menu-link" href="/#/AGB"
              title="AGB">
              AGB
            </a></li>
          <li class="footer-service-menu-item"><a class="footer-service-menu-link"
              href="/#/Datenschutz" title="Datenschutz">
              Datenschutz
            </a></li>
          <li class="footer-service-menu-item"><a class="footer-service-menu-link"
              href="/#/Impressum" title="Impressum">
              Impressum
            </a></li>
          <li class="footer-service-menu-item"><a class="footer-service-menu-link"
              href="/#/Widerrufsrecht" title="Widerrufsrecht">
              Widerrufsrecht
            </a></li>
        </ul>
      </div>

      <div class="footer-vat">
        <p>
          Alle Preise inkl. gesetzl. Mehrwertsteuer zzgl. Zulassungspauschale in Höhe von 89 € und ggf.
          Zustellungskosten.<br>Die gesamte Fahrzeugabwicklung erfolgt über die Fleetpool GmbH. Die monatliche Rate
          beinhaltet Kfz-Versicherung (Teil-, Vollkasko- und Haftpflichtversicherung), Kfz-Steuer, Werksfracht, gebuchte
          Freikilometer, Wartung &amp; Inspektion, Hauptuntersuchung und jahreszeitgerechte Bereifung. Betriebsstoffe
          wie z. B. Scheibenwaschwasser, AdBlue, Motoröl, Kühlflüssigkeiten sowie Kraftstoffe bzw. Strom sind nicht in
          der Rate enthalten.<br><br>*Fleetpool GmbH ist nach der Akquise durch ALD Automotive Teil der Societe Generale
          Gruppe geworden.
        </p>
      </div>

      <div class="footer-copyright">

      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterMain",
};
</script>

<style>
.footer-main {
    margin-top: 0 !important;
}

.vat-info--text {
  text-align: center !important;
}

.footer-main .column--content {
  display: block !important;
}

.footer-main .column--headline::after {
  display: none;
}
</style>
