
import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  { path: '/', component: () => import('../views/Home.vue') },
  { path: '/schadenmeldung', component: () => import('../views/Schadenmeldung.vue') },
  { path: '/faq', component: () => import('../views/Faq.vue') },
  { path: '/downloads', component: () => import('../views/Downloads.vue') },
  { path: '/kontakt', component: () => import('../views/Kontakt.vue') },
  { path: '/agb', component: () => import('../views/Agb.vue') },
  { path: '/zustell-und-zahlungsbedingungen', component: () => import('../views/ZustellUndZahlung.vue') },
  { path: '/impressum', component: () => import('../views/Impressum.vue') },
  { path: '/widerrufsrecht', component: () => import('../views/Widerrufsrecht.vue') },
  { path: '/datenschutz', component: () => import('../views/Datenschutz.vue') },
  { path: '/verhaltenskodex', component: () => import('../views/Verhaltenskodex.vue') },
  { path: '/whistleblowing', component: () => import('../views/Whistleblowing.vue') },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
    return { top: 0 }
  }
});

export default router;
